<template>
  <Header title="Nastavenia" />
  <div id="settings" class="container"></div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'

export default {
  name: 'Settings',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#settings {
}
</style>
